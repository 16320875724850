// EmailVerificationSuccess.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const EmailVerificationSuccess = () => {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Email Verified Successfully!</h1>
      <p>Your email has been verified. You can now log in and access all features.</p>
      <button 
        onClick={() => navigate('/')}
        style={{
          marginTop: '20px',
          padding: '10px 20px',
          fontSize: '16px',
          cursor: 'pointer'
        }}
      >
        Go to Login
      </button>
    </div>
  );
};

export default EmailVerificationSuccess;
