// InvalidVerification.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const InvalidVerification = () => {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Invalid or Expired Verification</h1>
      <p>The verification link is invalid or has expired. </p>
      
    </div>
  );
};

export default InvalidVerification;
